import styled from "styled-components";
import { HiChevronRight, HiArrowNarrowRight } from "react-icons/hi";
import YachtImages  from "../assets/image/yacht_image_licensed.e78c222b.jpg";
import MembershipImage  from "../assets/image/membership.png";
import HeaderComponent, { Logo } from "../components/header.component";
import CoverComponent from "../components/cover.component";
import FooterComponent from "../components/footer.component";
import { useNavigate } from "react-router";
import PageWrapperComponent from "../components/PageWrapper.component";

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};

const Wrapper = styled.div`
  width: 100%;
  height: 80px;
`;
const ServicesWrapper = styled.div`
  width: 900px;
  margin-top: 90px;
  display: flex;
  flex-direction: row;
  margin-left: 50px;
  justify-content: space-between;
  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    width: 100%;
  }
`;


const ImageImport = styled.img`
  width: 450px;
  height: 300px;
`;

const ImageWrapper = styled.div``;

const LoginWrapper = styled.div`
  margin-left: 30px;
`;

const LoginHeader = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-family: tahoma;
  cursor: pointer;
`;

const LoginCredentials = styled.div`
  display: flex;
  margin-top: 20px;
`;

const InputText = styled.input`
  padding: 10px;
  width: 260px;
  border: 1px solid #bdbcbb;
  border-radius: 3px;
`;

const ProcessLogin = styled.div`
  margin-left: 10px;
  padding: 10px;
  color: white;
  font-family: tahoma;
  background: #616363;
  font-size: 14px;
  cursor: pointer;
  border-radius: 3px;
`;

const LoginDescription = styled.div`
  font-size: 13px;
  margin-top: 10px;
  width: 300px;
  font-family: sans-serif;
`;

function Membership() {

  const navigate = useNavigate()
    function learnMoreDocking() {
      navigate('/yacht');
    }

    function loginToMembership() {
      navigate('/membership/login');
    }

  return (
    <Wrapper>
      <HeaderComponent />
      <CoverComponent /> <ServicesWrapper> 
          <ImageWrapper>
            <ImageImport  src = {MembershipImage} />    
          </ImageWrapper>
          <LoginWrapper>
            <LoginHeader onClick = {loginToMembership}>Click here to sign in</LoginHeader>
            <LoginDescription>
              Before you sign in you should have Jetnemo account. <br />

            </LoginDescription> 
          </LoginWrapper>    
      </ServicesWrapper>
      <FooterComponent />
    </Wrapper>
  );
}

export default Membership;
