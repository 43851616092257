import styled from "styled-components";
import { HiChevronRight, HiArrowNarrowRight } from "react-icons/hi";
import YachtImages  from "../assets/image/yacht_image_licensed.e78c222b.jpg";
import ConciergeImage  from "../assets/image/pexels-cottonbro-4606397.jpg";
import HeaderComponent, { Logo } from "../components/header.component";
import CoverComponent from "../components/cover.component";
import FooterComponent from "../components/footer.component";
import { useNavigate } from "react-router";
import PageWrapperComponent from "../components/PageWrapper.component";

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};

const Wrapper = styled.div`
  width: 100%;
  height: 80px;
`;
const ServicesWrapper = styled.div`
  width: 800px;
  min-height: 800px;
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  display: flex;
`;

const ConciergeText = styled.div`
 margin-left: 50px;
 padding-top: 50px;
`;

const ImgImport = styled.img`
  height: 515px;
  border-radius: 10px;
`;

const ConciergeHeader = styled.div`
  font-size: 30px;
  font-weight: 600;
  font-family: sans-serif;
`;

const ConciergeDescription = styled.div`
  font-size: 15px;
  font-weight: 200;
  line-height: 1.8;
  padding-top: 20px;
  font-family: sans-serif;
`;


const Services = styled.div`
  width: 800px;
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  margin-top: 10px;
`;

const Items = styled.div`
  width: 380px;
  height: 140px;
  cursor: pointer;
  font-family: sans-serif;
  background-color: #dee0df;
  box-sizing: border-box;
  padding: 15px;
  margin-right: 20px;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
    background-color: #2e74e6;
    color: white;
  }
`;

const ItemHeader = styled.div`
  font-weight: 400;
  font-size: 20px;
`;

const ItemDescription = styled.div`
  font-size: 14px;
  padding-top: 10px;
`;

function Concierge() {

  const navigate = useNavigate()
    function learnMoreDocking() {
      navigate('/yacht');
    }

    function learnMoreConcierge() {
      navigate('/concierge');
    }

  return (
    <Wrapper>
      <HeaderComponent />
      <CoverComponent /><ServicesWrapper>   
          <ImageContainer>
            <ImgImport src = { ConciergeImage } />
          </ImageContainer>
          <ConciergeText>
            <ConciergeHeader>Our Concierge Service Helps You</ConciergeHeader>
            <ConciergeDescription>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
            when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
            </ConciergeDescription>
            <Services>
          <Items>
            <ItemHeader>Pickup and drop-off </ItemHeader>
            <ItemDescription>Manage your flight crew - easy human management interface</ItemDescription>
          </Items>
          <Items>
            <ItemHeader>In-plane Amenities</ItemHeader>
            <ItemDescription>Get detailed information about private jet maintanence / scheduled maintanence</ItemDescription>
          </Items>
          <Items>
            <ItemHeader>Destination planning</ItemHeader>
            <ItemDescription>Manage / Plan Charters using Jetnemo Charter Solution</ItemDescription>
          </Items>
        </Services>
          </ConciergeText>    
      </ServicesWrapper>
      <FooterComponent />
    </Wrapper>
  );
}

export default Concierge;
