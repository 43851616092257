import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router";
import { useLocalStorage } from "./useLocalStorage";
const AuthContext = createContext<{token: any}>({token: ''});

export const AuthProvider = ({ children }) => {
    const userData = localStorage.getItem('user_data');
    const token = JSON.parse(userData!);
    const [user, setUser] = useLocalStorage('user', {token});
    const navigate = useNavigate();

    console.log(token);

    const login = async(data) => {
        setUser(data);
        navigate('/profile');
    }

    const logout = ()=> {
        setUser(null);
        navigate('/', { replace: true });
    };

    const value = useMemo(
        ()=> ({
            token,
            login,
            logout
        }),
        [user]
    );
    console.log(value);
    return <AuthContext.Provider value = {value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext);
}