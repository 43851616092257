import styled from "styled-components";
import Our_CEO  from "../../assets/image/our_ceo.png";
import HeaderComponent, { Logo } from "../../components/header.component";
import FooterComponent from "../../components/footer.component";
import { useNavigate } from "react-router";
import PageWrapperComponent from "../../components/PageWrapper.component";
import HeaderComponentDashboard from "../../components/HeaderDashboard";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};

const Wrapper = styled.div`
  width: 100%;
  height: 80px;
`;
const ServicesWrapper = styled.div`
  width: 800px;
  min-height: 800px;
  margin-top: 70px;
  margin-left: 20px;
  flex-wrap: wrap;
  font-family: tahoma;
  font-size: 14px;
  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    width: 100%;
  }
`;

const FoundersList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 30px;
`;

const Founder = styled.div``;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
const ImageWrapper = styled.div`
  width: 300px;
`;
const ImageImport = styled.img`
  width: 240px;
  height: 240px;
  border-radius: 2%;
`;
const DescriptionWrapper = styled.div`
  margin-left: 20px;
  color: #595a5c;
`;
const Fullname = styled.div`
  font-size: 22px;
  font-weight: 400;  
  color: #1a1a1c;
`;
const Position = styled.div`
font-size: 14px; 
`;
const Description = styled.div`
  margin-top: 30px;
  line-height: 1.7;
`;

const DashboardWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
`;

const MenuItemList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 100px;
  font-family: tahoma;
`;

const MenuItem = styled.div`
  height: 40px;
`;

const MenuBar = styled.div`
  width: 220px;
  background-color: #ebebeb;
`;

const LinkTo = styled(Link)`
  text-decoration: none;
  color: black;
`;

const VesselList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
`;

const VesselItem = styled.div`
  background-color: #f2f0f0;
  margin-bottom: 5px;
  font-family: tahoma;
  font-size: 13px;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
`;


const VesselColumn = styled.div`
  margin-right: 30px;
  min-width: 100px;
  display: flex;
`;

const AvaibaleStatus = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
  margin-top: 3px;
  margin-right: 10px;
`;

const UnAvaibaleStatus = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  margin-top: 3px;
  margin-right: 10px;
`;

const AddVessel = styled.div`
  width: 100px;
  text-align: center;
  cursor: pointer;
  padding-top: 22px;
`;

const VesselHeader = styled.div`
  display: flex;
  justify-content: space-between;

`;


function Dashboard() {
    const [user, setUser] =  useState<{id: number; firstName: string; lastName: string, company: {id: number, name: string}}>();
    const baseUrl = process.env.REACT_APP_API_ENV;
    const token = localStorage.getItem('user_data');
    console.log(JSON.parse(token!).token);
    useEffect(() => {
        axios.get(`${baseUrl}/auth/me`, { headers: { Authorization: "Bearer " + JSON.parse(token!).token} }).then((response: any)=> {
            setUser(response.data);
            
        }).catch((e: any) => {
            window.location.href = '/'
        });
     }, []);


     const [vessels, setVessel] = useState<any[]>();

     useEffect(() => {
        axios.get(`${baseUrl}/vessel`, { headers: { Authorization: "Bearer " + JSON.parse(token!).token} }).then((response: any)=> {
            setVessel(response.data);
            
        }).catch((e: any) => {
            console.log(e);
        });
     }, []);
 

     console.log(vessels);

  const navigate = useNavigate()
    function learnMoreDocking() {
      navigate('/yacht');
    }

    function logout() {
        localStorage.removeItem('user_data');
        navigate('/membership/login');
    }

    function gotToAddVessel() {
        navigate('/add-vessel');
    }

    function learnMoreConcierge() {
      navigate('/concierge');
    }

  return (
    <Wrapper>
      <HeaderComponentDashboard user = {user} />
      <DashboardWrapper>
        <MenuBar>
            <MenuItemList>
                <MenuItem><LinkTo to="/dashboard">Dashboard</LinkTo></MenuItem>
                <MenuItem><LinkTo to="/my-crew">My Crew</LinkTo></MenuItem>
                <MenuItem><LinkTo to="/settings">Settings</LinkTo></MenuItem>
                <MenuItem onClick={logout}>Sign Out</MenuItem>
            </MenuItemList>
        </MenuBar>
       
        <VesselList>
            <VesselHeader>
            <h4>Yachats</h4>
            <AddVessel onClick={gotToAddVessel}>+ Add Vessel</AddVessel>
            </VesselHeader>
        { vessels?.length ? vessels.map(item => (
            <VesselItem key={item.id}>
                <VesselColumn>{item.id}</VesselColumn>
                <VesselColumn>{item.name}</VesselColumn>
                <VesselColumn>{item.dock}</VesselColumn>
                <VesselColumn>{item.type}</VesselColumn>
                <VesselColumn>{item.country}, {item.city}</VesselColumn>
                <VesselColumn>{item.status}</VesselColumn>
                <VesselColumn>No Maintanance</VesselColumn>
                <VesselColumn> { item.use_status === 'AVAILABLE' ? <AvaibaleStatus /> : <UnAvaibaleStatus /> } {item.use_status}</VesselColumn>
            </VesselItem>
        )) : null }
        </VesselList>
      </DashboardWrapper>
      <FooterComponent />
    </Wrapper>
  );
} 

export default Dashboard;
