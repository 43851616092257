import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};


const Header = styled.div`
width: 100%;
background-color: #fafafa;
color: #383836;
height: 70px;
display: flex;
-webkit-box-flex: 1;
flex-grow: 1;
-webkit-box-pack: justify;
justify-content: space-between;
align-items: center;

@media ${devices.tablet} {
  justify-content: center;
}

`;

export const Logo = styled.div`
font-family: "Roboto Light";
font-style: normal;
font-weight: 600;
vertical-align: center;
padding-left: 20px;
font-family: tahoma;
letter-spacing: -1px;
font-weight: 800;
color: #383836;
padding-left: 20px;
font-size: 35px;
cursor: pointer;
`;

export const LogoWhite = styled.div`
font-family: "Roboto Light";
font-style: normal;
font-weight: 600;
vertical-align: center;
padding-left: 20px;
font-family: tahoma;
letter-spacing: -1px;
font-weight: 800;
color: white;
padding-left: 20px;
font-size: 35px;
`;

const MenuBar = styled.div`
  display: flex;
  flex-direction: row;

  @media ${devices.tablet} {
    display: none;
  }
`;

const Menu = styled.div`
  margin-right: 20px;
  display: flex;
`;

const MenuItems = styled.div`
  margin-left: 10px;
  margin-top: 10px;
  font-family: sans-serif;
  letter-spacing: 0px;
  font-weight: 100;
  font-size: 15px;
  border-right: 1px solid #a3a3a3;
  padding-right: 10px;
  height: 20px;
  
  &:last-child {
    border-right: none;
  }
`; 



const RequestDemo = styled.div`
  background: #616363;
  height: 20px;
  margin-right: 10px;
  padding: 10px;
  font-family: tahoma;
  font-weight: 300;
  font-size: 14px;
  color: #ffff;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  @media ${devices.tablet} {
    display: none;
  }
`;

const LinkTo = styled(Link)`
  text-decoration: none;
  color: black;
`;


function HeaderComponent() {

  const navigate = useNavigate()
    function handleDemoRequest() {
      navigate('/membership/login');
    }

    function handleHome() {
      navigate('/');
    }
  
    return (<Header>
      <Logo onClick = {handleHome}>Jetnemo</Logo>
      <MenuBar>
        <Menu>
          <MenuItems><LinkTo to="/private-jet" >Private Jet</LinkTo></MenuItems>
          <MenuItems><LinkTo to="/yacht" >Yacht</LinkTo></MenuItems>
          <MenuItems><LinkTo to="/helicopter" >Helicopter & Light Aircraft</LinkTo></MenuItems>
          <MenuItems><LinkTo to="/concierge">Concierge</LinkTo></MenuItems>
          <MenuItems><LinkTo to="/membership">Membership</LinkTo></MenuItems>
        </Menu>
        <RequestDemo onClick= { handleDemoRequest }>Sign In</RequestDemo>
      </MenuBar>
    </Header>);
};

export default HeaderComponent;
      
      