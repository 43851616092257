import styled from "styled-components";
import Our_CEO  from "../../assets/image/our_ceo.png";
import HeaderComponent, { Logo } from "../../components/header.component";
import FooterComponent from "../../components/footer.component";
import { useNavigate } from "react-router";
import PageWrapperComponent from "../../components/PageWrapper.component";
import HeaderComponentDashboard from "../../components/HeaderDashboard";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};

const Wrapper = styled.div`
  width: 100%;
  height: 80px;
`;
const ServicesWrapper = styled.div`
  width: 800px;
  min-height: 800px;
  margin-top: 70px;
  margin-left: 20px;
  flex-wrap: wrap;
  font-family: tahoma;
  font-size: 14px;
  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    width: 100%;
  }
`;

const FoundersList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 30px;
`;

const Founder = styled.div``;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
const ImageWrapper = styled.div`
  width: 300px;
`;
const ImageImport = styled.img`
  width: 240px;
  height: 240px;
  border-radius: 2%;
`;
const DescriptionWrapper = styled.div`
  margin-left: 20px;
  color: #595a5c;
`;
const Fullname = styled.div`
  font-size: 22px;
  font-weight: 400;  
  color: #1a1a1c;
`;
const Position = styled.div`
font-size: 14px; 
`;
const Description = styled.div`
  margin-top: 30px;
  line-height: 1.7;
`;

const DashboardWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
`;

const MenuItemList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 100px;
  font-family: tahoma;
`;

const MenuItem = styled.div`
  height: 40px;
`;

const MenuBar = styled.div`
  width: 220px;
  background-color: #ebebeb;
`;

const LinkTo = styled(Link)`
  text-decoration: none;
  color: black;
`;

const VesselList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
`;

const VesselItem = styled.div`
  background-color: #f2f0f0;
  margin-bottom: 5px;
  font-family: tahoma;
  font-size: 13px;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;


const VesselColumn = styled.div`
  margin-right: 30px;
  min-width: 100px;
  display: flex;
`;

const AvaibaleStatus = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
  margin-top: 3px;
  margin-right: 10px;
`;

const UnAvaibaleStatus = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
  margin-top: 3px;
  margin-right: 10px;
`;

const AddVessel = styled.div`
  width: 100px;
  text-align: center;
  cursor: pointer;
  padding-top: 22px;
`;

const VesselHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;

`;

const InptTitle = styled.div``;


const InputField = styled.input`
    width: 80px;
    height: 40px;
    border: none;
    background: #ebebeb;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding-left: 10px;
    margin-left: 5px;
`;

const FormGroup = styled.div`
    margin-top: 20px;
`;

const InputGroup = styled.div`
    font-size: 15px;
    font-family: tahoma;
`;

const AddVesselBtn = styled.div`
    width: 280px;
    height: 40px;
    cursor: pointer;
    background: #383838;
    color: white;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: tahoma;
    &:hover {
        opacity: 80%;
    }
`;


const Rrequirment = styled.div`
    display: flex;
    flex-direction: column;
`;

const SelectInput = styled.select`
    width: 200px;
    height: 38px;
`;

const CreateRequirementWrapper = styled.div`
    padding: 30px;
    display: flex;
`;

const OptionInput = styled.option`
    height: 30px;

`;

const SaveButton = styled.option`
    width: 100px;
    text-align: center;
    height: 38px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: black;
    cursor: pointer;
    margin-left: 10px;
`;

const ConnectedRequirements = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
`;

const RequirementWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Items = styled.div`
    width: 100%;
    height: 30px;
    padding: 20px;
    background-color: #e0e0e0;
    margin-bottom: 2px;
    font-family: tahoma;
    display: flex;
    justify-content: space-between;
`;

const ItemName = styled.div`
    width: 200px;
`;

const ItemQuantity = styled.div`
    width: 100px;
`;

const ItemNameHeader = styled.div`
    width: 200px;
`;

const ItemQuantityHeader = styled.div`
    width: 100px;
`;

const RequirementHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;


const CreateRequirement = styled.div`
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    margin-left: 30px;
    cursor: pointer;
    color: white;
    background-color: red;
`;

const RequirementSum = styled.div`
    padding: 30px;
`;


const RequirementList = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
`;


const RequirementItems = styled.div`
    display: flex;
    flex-direction: row;
`;

const RequirementItem = styled.div`
    display: flex;
    flex-direction: row;
    background-color: gray;
    margin: 2px;
    width: 160px;
    padding: 10px;
`;


const EmployeeTable = styled.div`
    margin-left: 20px;
    width: 300px;
    display: flex;
    flex-direction: column;
`;

const EmployeeRenderList = styled.div`
    display: flex;
`;

const EmployeeList = styled.div`
`;


const EmployeeItem = styled.div`
    margin-left: 5px;
    margin-top: 5px;
    background-color: red;
`;


const HiredEmployessList = styled.div`
    display: flex;
    flex-direction: column;
    width: 300px;
`;

const HiredEmployessItem = styled.div`
    display: flex;
    flex-direction: row;
    width: 300px;
    margin-top: 10px;
`;

function CrewDetailsPage() {

    const params = useParams();

    const [positionName, setPositionName] = useState<string>('');
    const [requirementsList, setRequirementsList] =  useState<{ id: number, position: {id: number; name: string},  
    quantity: string, left: string, 
    hirings: [{id: number, status: string, user: { id: number, firstName: string, lastName: string }}]}[]>([]);
    const [quantity, setQuantity] = useState<string>('');
    const [requirements, setRequirements] = useState<{positionName: { id: number, name: string }, quantity: string}[]>([]);
const [user, setUser] =  useState<{id: number; firstName: string; lastName: string, company: {id: number, name: string}}>();
    const [positions, setPositions] =  useState<{id: number; name: string; type: string}[]>([]);
    
    const baseUrl = process.env.REACT_APP_API_ENV;
    const token = localStorage.getItem('user_data');
    useEffect(() => {
        axios.get(`${baseUrl}/auth/me`, { headers: { Authorization: "Bearer " + JSON.parse(token!).token} }).then((response: any)=> {
            setUser(response.data);
            
        }).catch((e: any) => {
            window.location.href = '/'
        });
     }, []);


     useEffect(() => {
        axios.get(`${baseUrl}/positions`, { headers: { Authorization: "Bearer " + JSON.parse(token!).token} }).then((response: any)=> {
            setPositions(response.data);
            
        }).catch((e: any) => {
            window.location.href = '/'
        });
     }, []);


    console.log(positions);


     const [crew, setCrew] = useState<{id: number, name: string, description: string, country: string, city: string}>();

     

     useEffect(() => {
        axios.get(`${baseUrl}/crew/${params.crewId}`, { headers: { Authorization: "Bearer " + JSON.parse(token!).token} }).then((response: any)=> {
            setCrew(response.data);
            
            
        }).catch((e: any) => {
            console.log("CHA", e);
            console.log(e);
        });
     }, []);


     const [hiredUsers, setHiredUsers] = useState<{id: number; firstName: string; lastName: string}[]>();     

     useEffect(() => {
      axios.get(`${baseUrl}/requirements/hired/${params.crewId}`, { headers: { Authorization: "Bearer " + JSON.parse(token!).token} }).then((response: any)=> {
        setHiredUsers(response.data);
          console.log("hired", response.data);
          
      }).catch((e: any) => {
      });
   }, []);

  const navigate = useNavigate();

  function createRequirement() {
      const data = requirements.map((item)=> {
          return {
              crew: parseInt(params.crewId!),
              position: item.positionName.id,
              quantity: parseInt(item.quantity)
          }
      });

      console.log(data);
      axios.post(`${baseUrl}/requirements`,data, { headers: {  Authorization: "Bearer " + JSON.parse(token!).token }}).then((response: any)=> {
        console.log("NAVIGATION");
        navigate(`/my-crew`);
    }).catch((e: any) => {
        console.log(e);
    }); 
  }

  useEffect(() => {
    axios.get(`${baseUrl}/requirements/${params.crewId}`, { headers: { Authorization: "Bearer " + JSON.parse(token!).token} }).then((response: any)=> {
        setRequirementsList(response.data);
        console.log("OOOOOOOOO", response.data);
    }).catch((e: any) => {
        console.log(e);
    });
 }, []);

  function addRequirement() {

    axios.get(`${baseUrl}/positions/${positionName}`, { headers: { Authorization: "Bearer " + JSON.parse(token!).token} }).then((response: any)=> {
        setRequirements([...requirements, {quantity, positionName: { id: response.data.id, name: response.data.name}}]);
        
    }).catch((e: any) => {
        console.log("CHA", e);
        console.log(e);
    });
        
      console.log(positionName, quantity);
  }

    function logout() {
        localStorage.removeItem('user_data');
        navigate('/membership/login');
    }

  return (
    <Wrapper>
      <HeaderComponentDashboard user = {user} />
      <DashboardWrapper>
        <MenuBar>
            <MenuItemList>
                <MenuItem><LinkTo to="/dashboard">Dashboard</LinkTo></MenuItem>
                <MenuItem><LinkTo to="/my-crew">My Crew</LinkTo></MenuItem>
                <MenuItem><LinkTo to="/settings">Settings</LinkTo></MenuItem>
                <MenuItem onClick={logout}>Sign Out</MenuItem>
            </MenuItemList>
        </MenuBar>
       
       <RequirementWrapper>
            <Rrequirment>

                <CreateRequirementWrapper>
                <SelectInput onChange={(e)=> setPositionName(e.target.value) } >
                    <option>Select Position</option>
                    {positions && positions.length ? positions.map((item)=> {
                        return <OptionInput key={item.id} value={item.id}>{item.name} {item.id}</OptionInput>
                    } ): null}
                </SelectInput>
                <InputField placeholder="Quantity" onChange={(e)=> setQuantity(e.target.value) }/>
                <SaveButton onClick={addRequirement}>Save</SaveButton>
                </CreateRequirementWrapper>

            </Rrequirment>

            <ConnectedRequirements>
                {requirements && requirements.length ?
                <RequirementHeader>
                <ItemNameHeader>Name</ItemNameHeader>  
                <ItemQuantityHeader>Quantity</ItemQuantityHeader>
                </RequirementHeader> : null } 
            {requirements && requirements.length ? requirements.map((item)=> {
                return <Items key={item.positionName.id}><ItemName>{item.positionName.name}</ItemName><ItemQuantity>{item.quantity} position</ItemQuantity> </Items>
            }) : null}
            </ConnectedRequirements> 
            
            {requirements && requirements.length ?
            <CreateRequirement onClick={createRequirement}>
                Create Requirement
            </CreateRequirement> : null }


            <TotalPrice requirements={requirements} />
            

            <RequirementList>
                {requirementsList && requirementsList.length ?  requirementsList.map((item)=> {
                 return (<><RequirementItems key={item.id}>
                 <RequirementItem>{item.position.name}</RequirementItem>
                 <RequirementItem>{item.quantity}</RequirementItem>
                 <RequirementItem>{item.left} left</RequirementItem>
          </RequirementItems>
          <EmployeeTable>
              {item.hirings && item.hirings.length ?  item.hirings.map((hire)=> {
              return (
                <EmployeeRenderList key={hire.id}>
                <EmployeeItem>{hire.id}</EmployeeItem>
                <EmployeeItem>{hire.status}</EmployeeItem>
                <EmployeeItem>{hire.user.firstName} {hire.user.lastName}</EmployeeItem>
                </EmployeeRenderList>
              )
              }) : null}
             
          </EmployeeTable>
          </>);
 }) : null }
               
            </RequirementList>


            <HiredEmployessList> Hired Userssss
              {hiredUsers && hiredUsers.length ? <>{hiredUsers.map((data)=> <HiredEmployessItem>{ data.firstName }</HiredEmployessItem>)}</> : null}
            </HiredEmployessList>

            </RequirementWrapper>
            
            
          
      </DashboardWrapper>
      <FooterComponent />
    </Wrapper>
  );
} 


function TotalPrice(props: any) {
    return <RequirementSum>
        Total Positions required: {props.requirements.reduce(function(prev, current) {
             return prev + +current.quantity
             }, 0)}
    </RequirementSum>
}

export default CrewDetailsPage;
