import styled from "styled-components";
import { HiChevronRight, HiArrowNarrowRight } from "react-icons/hi";
import YachtImages  from "../assets/image/yacht_image_licensed.e78c222b.jpg";
import Our_CEO  from "../assets/image/our_ceo.png";
import HeaderComponent, { Logo } from "../components/header.component";
import CoverComponent from "../components/cover.component";
import FooterComponent from "../components/footer.component";
import { useNavigate } from "react-router";
import PageWrapperComponent from "../components/PageWrapper.component";

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};

const Wrapper = styled.div`
  width: 100%;
  height: 80px;
`;
const ServicesWrapper = styled.div`
  width: 800px;
  min-height: 800px;
  margin-top: 70px;
  margin-left: 20px;
  flex-wrap: wrap;
  font-family: tahoma;
  font-size: 14px;
  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    width: 100%;
  }
`;

const FoundersList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 30px;
`;

const Founder = styled.div``;

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
const ImageWrapper = styled.div`
  width: 300px;
`;
const ImageImport = styled.img`
  width: 240px;
  height: 240px;
  border-radius: 2%;
`;
const DescriptionWrapper = styled.div`
  margin-left: 20px;
  color: #595a5c;
`;
const Fullname = styled.div`
  font-size: 22px;
  font-weight: 400;  
  color: #1a1a1c;
`;
const Position = styled.div`
font-size: 14px; 
`;
const Description = styled.div`
  margin-top: 30px;
  line-height: 1.7;
`;

function About() {

  const navigate = useNavigate()
    function learnMoreDocking() {
      navigate('/yacht');
    }

    function learnMoreConcierge() {
      navigate('/concierge');
    }

  return (
    <Wrapper>
      <HeaderComponent />
        <PageWrapperComponent pageName ="About" pageDescription = "" />
        <ServicesWrapper> 
        Jetnemo is widening the gap in private jet, yacht, helicopter. Our technology solves how you can manage private jet, yacht, helicopter.   
        <FoundersList>
          <Title>Management</Title>
          <Founder>
            <Container>
              <ImageWrapper>
                <ImageImport src = {Our_CEO} />
              </ImageWrapper>
              <DescriptionWrapper>
                <Fullname>Apolon Pachulia</Fullname>
                <Position>Founder & CEO</Position>
                <Description>
                  Apolon Pachulia is founder and Chief Executive Officer at Jetnemo. 
                  Apolon has several years experience in different business domains - logistics, finance, ecommerce,
                  health care, entertainment and etc. He worked for several large organizations in Georgia and was Senior/Lead developer for silicon valley based
                  startups. Now he is focused on to build technology solutions for private jets,
                  yachts and helicopters.  
                </Description>
              </DescriptionWrapper>
            </Container>
          </Founder>
        </FoundersList>
        </ServicesWrapper> 
      <FooterComponent />
    </Wrapper>
  );
}

export default About;
