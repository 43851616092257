import styled from "styled-components";
import { HiChevronRight, HiArrowNarrowRight } from "react-icons/hi";
import YachtImages  from "../assets/image/yacht_image_licensed.e78c222b.jpg";
import HelicopterImage  from "../assets/image/pexels-matheus-bertelli-2868226.jpg";
import HeaderComponent, { Logo } from "../components/header.component";
import CoverComponent from "../components/cover.component";
import FooterComponent from "../components/footer.component";
import { useNavigate } from "react-router";
import PageWrapperComponent from "../components/PageWrapper.component";

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};

const Wrapper = styled.div`
  width: 100%;
  height: 80px;
`;
const ServicesWrapper = styled.div`
  width: 800px;
  min-height: 500px;
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    width: 100%;
  }
`;

const ImageImport = styled.img`
  width: 300px;
  height: 450px;
`;

const ImageWrapper = styled.div`
  width: 320px;
`;
const DescriptionWapper = styled.div`
  margin-left: 40px;
  margin-top: 100px;
`;
const Header = styled.div`
  font-size: 24px;
  font-weight: 600;
  font-family: tahoma;
`;

const TextWrapper = styled.div`
  font-size: 17px;
  margin-top: 20px;
`;

function Helicopter() {

  const navigate = useNavigate()
    function learnMoreDocking() {
      navigate('/yacht');
    }

    function learnMoreConcierge() {
      navigate('/concierge');
    }

  return (
    <Wrapper>
      <HeaderComponent />
      <CoverComponent /> <ServicesWrapper>  
          <ImageWrapper>
            <ImageImport src = {HelicopterImage} /> 
          </ImageWrapper> 
          <DescriptionWapper>
            <Header>Apply your Helicopter</Header>
            <TextWrapper>
              Jetnemo Helicopter helps you manage helicopter full-range management, availability, and service management
              <br /><br />
              Jetnemo Helicopter helps you manage helicopter full-range management, availability, and service management
            </TextWrapper>
          </DescriptionWapper>  
      </ServicesWrapper>
      <FooterComponent />
    </Wrapper>
  );
}

export default Helicopter;
