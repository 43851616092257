import styled from "styled-components";
import { HiChevronRight, HiArrowNarrowRight } from "react-icons/hi";
import YachtImages  from "./assets/image/yacht_image_licensed.e78c222b.jpg";
import PrivateJetImage  from "./assets/image/private_jet_licensed.059a6259.jpg";
import HeaderComponent, { Logo } from "./components/header.component";
import CoverComponent from "./components/cover.component";
import FooterComponent from "./components/footer.component";
import { useNavigate } from "react-router";

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};

const Wrapper = styled.div`
  width: 100%;
  height: 80px;
`;

const LearnMoreWhiteButton = styled.div`
  display: flex;
  background: #ffffff;
  height: 25px;
  margin-right: 10px;
  padding: 10px;
  font-family: tahoma;
  font-weight: 300;
  font-size: 14px;
  color: #292828;
  padding-left: 20px;
  margin-top: 20px;
  width: 120px;
  padding-right: 20px;
  margin-left: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;


const LearnMoreButton = styled.div`
  display: flex;
  background: #303030;
  height: 25px;
  margin-right: 10px;
  padding: 10px;
  font-family: tahoma;
  font-weight: 300;
  font-size: 14px;
  color: #ffff;
  padding-left: 20px;
  margin-top: 20px;
  width: 120px;
  padding-right: 20px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const ServicesWrapper = styled.div`
  width: 800px;
  margin: 0 auto;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    width: 100%;
  }
`;

const ServicesList = styled.div`
  width: 390px;
  height: 200px;
  background:#e6e1e1;
  box-sizing: border-box;
  padding: 20px;
  font-family: sans-serif;
  margin-top: 20px;
  &:hover {
    color: white;
    background: black;
    cursor: pointer;
  }
  @media ${devices.tablet} {
    width: 100%;
  }
`;

const ServiceTitle = styled.div`
    font-weight: 500;
    font-style: normal;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: 0px;
`;

const ServiceDescription = styled.div`
    font-weight: 100;
    font-style: normal;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: 0px;
    margin-top: 30px;
`;

const YachtWrapper = styled.div`
  width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const YachtText = styled.div`
  display: flex;
  flex-direction: column;
  width: 550px;
  margin-top: 80px;
  margin-bottom: 80px;
  line-height: 1.5;
  @media ${devices.tablet} {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
  }
`;

const YTHeader = styled.div`
  font-size: 35px;
  font-weight: 600px;
  line-height: 1;
  font-family: sans-serif;
`;

const YTText = styled.div`
  font-size: 17px;
  font-weight: 200px;
  margin-top: 30px;
  font-weight: 100;
  font-family: sans-serif;
`;

const YachtImage = styled.div`
  width: 350px;
  text-align: right;
  padding-top: 20px;
  padding-bottom:20px;
  @media ${devices.tablet} {
    display: none;
    width: 100%;
  }
`;

const Arrow = styled.div`
  padding-top: 0px;
  padding-left: 10px;
  font-size: 20px;
`;

const MainYachtWrapper = styled.div`
  width: 100%;
  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
  }
  
`;

const MainJetWrapper = styled.div`
  margin: 0 auto;
  width: 900px;
  display: flex;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const PrivateJetDescription = styled.div`
  width: 430px;
  background-color: #303030;
  height: 293px;
  display: flex;
  flex-direction: column;
  @media ${devices.tablet} {
    width: 100%;
  }
`;

const JetHeader = styled.div`
  font-family: tahoma;
  font-weight: 600;
  padding: 20px;
  color: #ffffff;
  font-size: 30px;
`;

const JetText = styled.div`
  font-weight: 200;
  padding: 20px;
  color: #ffffff;
  font-size: 16px;
  font-family: sans-serif;
  line-height: 1.2;
`;

const JetImage = styled.div`
  width: 50%;
  @media ${devices.tablet} {
    width: 100%;
  }
`;

const ImgYacht = styled.img`
  width: 300px;
  @media ${devices.tablet} {
    width: 100%;
  }
`;


const PJImage = styled.img`
  width: 450px;
  @media ${devices.tablet} {
    width: 100%;
  }
`;

function Home() {

  const navigate = useNavigate()
    function learnMoreDocking() {
      navigate('/yacht');
    }

    function learnMoreConcierge() {
      navigate('/concierge');
    }

  return (
    <Wrapper>
      <HeaderComponent />

      <CoverComponent />

      <ServicesWrapper>
        <ServicesList>
          <ServiceTitle>Private Jet Crew Management</ServiceTitle>
          <ServiceDescription>
            The platform enables you to manage crew and all related services for your private jet
          <Arrow><HiArrowNarrowRight /></Arrow>
          </ServiceDescription>
        </ServicesList>

        <ServicesList>
          <ServiceTitle>Yacht Crew Management</ServiceTitle>
          <ServiceDescription>
            Our Platform enables you setup and manage your yacht crew and all related services.
            <Arrow><HiArrowNarrowRight /></Arrow>
          </ServiceDescription>
        </ServicesList>

        <ServicesList>
          <ServiceTitle>Helicopter Crew Management</ServiceTitle>
          <ServiceDescription>
            Jetnemo offers you manage helicopter crew hire / setup and 
            <Arrow><HiArrowNarrowRight /></Arrow>
          </ServiceDescription>
        </ServicesList>


        <ServicesList>
          <ServiceTitle>Yacht Club Membership</ServiceTitle>
          <ServiceDescription>
          Join and manage Yacht club membership in more than 100+ yacht clubs around the world
            <Arrow><HiArrowNarrowRight /></Arrow>
          </ServiceDescription>
        </ServicesList>
      </ServicesWrapper>
      <MainYachtWrapper>
      <YachtWrapper>
        <YachtText>
          <YTHeader>Yacht Club & Dock Managements</YTHeader>
          <YTText>
          Jetnemo platform supports yacht club & dock management. Manage administrative tasks, organize and analyze membership data and improve communication
          <br /><br />
          Manage dock availability and organize reservations and get full report
          </YTText>
          <LearnMoreButton onClick = { learnMoreDocking }>Learn more <Arrow><HiChevronRight /></Arrow></LearnMoreButton>
        </YachtText>
        <YachtImage>
          <ImgYacht src={YachtImages} />
        </YachtImage>
      </YachtWrapper>
      </MainYachtWrapper>
      <MainJetWrapper>
        <JetImage>
          <PJImage src={PrivateJetImage} />
        </JetImage>
        <PrivateJetDescription>
          <JetHeader>Jetnemo Concierge</JetHeader>
          <JetText>Manage third-party concierge services through the platform and extend your service</JetText>
          <LearnMoreWhiteButton onClick = { learnMoreConcierge }>Learn more <Arrow><HiChevronRight /></Arrow></LearnMoreWhiteButton>
        </PrivateJetDescription>
      </MainJetWrapper>
      <FooterComponent />
    </Wrapper>
  );
}

export default Home;
