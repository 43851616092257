import styled from "styled-components";
import { HiChevronRight, HiArrowNarrowRight } from "react-icons/hi";
import YachtImages  from "../assets/image/yacht_image_licensed.e78c222b.jpg";
import ImageOne  from "../assets/image/pexels-tanathip-rattanatum-2026324.jpg";
import ImageTwo  from "../assets/image/pexels-asad-photo-maldives-240524.jpg";
import Crew_home  from "../assets/image/crew_login.png";
import Crew_Dashboard  from "../assets/image/crew_dashboard.png";
import HeaderComponent, { Logo } from "../components/header.component";
import CoverComponent from "../components/cover.component";
import FooterComponent from "../components/footer.component";
import { useNavigate } from "react-router";
import PageWrapperComponent from "../components/PageWrapper.component";

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};

const Wrapper = styled.div`
  width: 100%;
  height: 80px;
`;
const ServicesWrapper = styled.div`
  width: 800px;
  margin-top: 70px;
  display: flex;
  margin-left: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    width: 100%;
  }
`;

const CoverText = styled.div`
    font-size: 72px;
    line-height: 112%;
    font-weight: 700;
    color: #7958e9;
    font-family: 'True Sans', sans-serif;
    width: 600px;
    margin: 0 auto;
    display: flex;
    width: 680px;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    padding-top: 50px;
`;

const CareersCover = styled.div`
  width: 100%;
  height: 370px;
  background-color: #f3efff;
`;

const CoverDesciption = styled.div`
  width: 700px;
  margin: 0 auto;
  color: #6a7686;
  font-size: 18px;
  font-family: 'True Sans', sans-serif;
  text-align: center;
  line-height: 112%;
  font-weight: 400;
  font-weight: 200;
  padding-top: 20px;
`;

const HiringWrapper = styled.div`
  width: 600px;
  margin: 0 auto;
  text-align: center;
`;

const HiringTitle = styled.div`
  font-family: 'True Sans', sans-serif;
    color: #061a36;
    font-size: 56px;
    margin-top: 20px;
    line-height: 20px;
    line-height: 120%;
    font-weight: 700;
`;

const HiringDescription = styled.div`
margin-bottom: 0px;
color: #6a7686;
font-size: 20px;
line-height: 120%;
font-family: 'True Sans', sans-serif;
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 800px;
  margin: 0 auto;
  margin-top: 50px;
  justify-content: space-between;
  margin-bottom: 60px;
`;

const ImageImport = styled.img`
  width: 380px;
  height: 280px;
`;

function Careers() {

  const navigate = useNavigate()
    function learnMoreDocking() {
      navigate('/yacht');
    }

    function learnMoreConcierge() {
      navigate('/concierge');
    }

  return (
    <Wrapper>
      <HeaderComponent />
        <CareersCover>
          <CoverText>
          Find your place with us at Jetnemo
          </CoverText>
          <CoverDesciption>
            If you are someone who enjoys solving hard problems, likes collaborating with a team, 
            is passionate about our mission, and loves a good pizza, we would love to chat! We hire from all industries and look for a diverse set of experiences in our talent.
          </CoverDesciption>
        </CareersCover>
        <ImagesContainer>
          <ImageImport src = {ImageOne} />
          <ImageImport src = {ImageTwo} />
        </ImagesContainer>
        <HiringWrapper>
        <HiringTitle>
        We're hiring!
        </HiringTitle>
        <HiringDescription>
        We're growing our team with jobs in many different departments! If you think you may be a fit, or know someone who may, check out the jobs below. We'd love to connect.
        </HiringDescription>
        
        </HiringWrapper>

        <ImagesContainer>
          <ImageImport src = {Crew_home} />
          <ImageImport src = {Crew_Dashboard} />
        </ImagesContainer>
        <ServicesWrapper>   
      </ServicesWrapper>
      <FooterComponent />
    </Wrapper>
  );
}

export default Careers;
