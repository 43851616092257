import styled from "styled-components";
import { HiChevronRight, HiArrowNarrowRight } from "react-icons/hi";
import { Logo, LogoWhite } from "../../components/header.component";
import { useState } from "react";
import axios from "axios";

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
`;

const ContentWrapper = styled.div`
    margin-right: 20px;
    display: flex;
    text-align: right;
    color: white;
    justify-content: center; 
    flex-direction: column; 
    align-items: right;
`;

const TitleHeader = styled.div`
    color: white;
    font-size: 25px;
    font-family: tahoma;
`;


const SignInWrapper = styled.div`
    margin-top: -50px;
`;

const Description = styled.div`
    font-size: 15px;
    color: white;
    font-family: tahoma;
`;

const LeftSide = styled.div`
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: right;
    background-color: #6381CC;
`;

const RightSide = styled.div`
    width: 50%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
`;


const JetnemoLogo = styled.div`
font-family: "Roboto Light";
font-style: normal;
font-weight: 600;
vertical-align: center;
font-family: tahoma;
letter-spacing: -1px;
font-weight: 800;
color: white;
font-size: 35px;
`;

const SignTitle = styled.div`
    font-size: 24px;
    font-family: tahoma;
`;

const FormGroup = styled.div`
    margin-top: 20px;
`;

const InputGroup = styled.div`
    font-size: 15px;
    font-family: tahoma;
`;

const InptTitle = styled.div``;

const InputField = styled.input`
    width: 280px;
    height: 40px;
    border: none;
    background: #ebebeb;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding: 7px;
    margin-top: 4px;
`;


const ForgotPassword = styled.a`
    color: black;
    font-family: "Roboto Light";
    font-size: 14px;
`;

const CopyRight = styled.div`
    position: absolute;
    right: 10px;
    bottom: 10px;
    font-family: tahoma;
    font-size: 14px;
    color: #858585;
`;

const SignInBtn = styled.div`
    width: 280px;
    height: 40px;
    cursor: pointer;
    background: #383838;
    color: white;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: tahoma;
    &:hover {
        opacity: 80%;
    }
`;

const CreateAccount = styled.div`
    width: 280px;
    height: 40px;
    cursor: pointer;
    background: none;
    border: 1px solid #8f8c8c;
    color: #8f8c8c;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: tahoma;
    &:hover {
        opacity: 80%;
    }
`;

const ErrorMessage = styled.div`
    width: 260px;
    padding: 10px;
    background-color: #e0baba;
    font-family: tahoma;
    font-size: 12px;
    margin-bottom: 10px;
`;


const OrSplitter = styled.div`
    width: 280px;
    text-align: center;
    font-family: tahoma;
    margin-top: 20px;
    margin-bottom: 20px;
`;

function LoginMembership() {
    const baseUrl = process.env.REACT_APP_API_ENV;
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [password, setPassword] = useState('');
    const handleLogin = (e) => {
        e.preventDefault();
        axios.post(`${baseUrl}/auth/login`, {email, password}).then((response: any)=> {
            localStorage.setItem('user_data', JSON.stringify({token: response.data.token}));
            console.log(response.data.user.role);
            console.log(response.data.token);
            if (response.data.user.role === 'EMPLOYEE') {
                window.location.href = '/employee'
            } else {
                window.location.href = '/dashboard';
            }
        }).catch((e: any) => {
            console.log(e);
            setError('Something went wrong, please try again!');
            //window.location.href = '/membership/login'
        });
      }

  return (
    <Wrapper>
      <LeftSide>
        <ContentWrapper>
            <JetnemoLogo>Jetnemo</JetnemoLogo>
            <TitleHeader>Crew Management</TitleHeader>
            <Description>
                Create, Organize and Hire your Airplane, Yacht or Helicopter crew
            </Description>
        </ContentWrapper>
      </LeftSide>
      <RightSide>
          <SignInWrapper>
            <SignTitle>Sign In</SignTitle>
            <FormGroup>
                {error ? <ErrorMessage>{error}</ErrorMessage> : null }
                <InputGroup>
                    <InptTitle>Email Address</InptTitle>
                    <InputField placeholder = "example@example.com" onChange={(e)=> setEmail(e.target.value) } />
                </InputGroup>
                <InputGroup>
                    <InptTitle>Password</InptTitle>
                    <InputField type="password" placeholder = "********" onChange={(e)=> setPassword(e.target.value) }/>
                </InputGroup>
                <ForgotPassword href="">Forgot Password?</ForgotPassword>
                <SignInBtn onClick={handleLogin}>Sign In</SignInBtn>
                <OrSplitter>Or</OrSplitter>
                <CreateAccount>Create An Account</CreateAccount>
            </FormGroup>
          </SignInWrapper>
      </RightSide>
      <CopyRight>Jetnemo Crew Management v1.0</CopyRight>
    </Wrapper>
  );
}

export default LoginMembership;
