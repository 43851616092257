import styled from "styled-components";
import { HiChevronRight, HiArrowNarrowRight } from "react-icons/hi";
import YachtImages  from "../assets/image/yacht_image_licensed.e78c222b.jpg";
import PrivateJetImage  from "../assets/image/private_jet_licensed.059a6259.jpg";
import PJScreen1  from "../assets/image/jet_management_screen1.png";
import HeaderComponent, { Logo } from "../components/header.component";
import CoverComponent from "../components/cover.component";
import FooterComponent from "../components/footer.component";
import { useNavigate } from "react-router";
import PageWrapperComponent from "../components/PageWrapper.component";

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};

const Wrapper = styled.div`
  width: 100%;
  height: 80px;
`;
const ServicesWrapper = styled.div`
  width: 800px;
  margin-left: 20px;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    width: 100%;
  }
`;

const Description = styled.div``;

const Services = styled.div`
  width: 800px;
  display: flex;
  flex-wrap: wrap;
  align-items: top;
  margin-top: 10px;
`;

const Items = styled.div`
  width: 380px;
  height: 140px;
  cursor: pointer;
  font-family: sans-serif;
  background-color: #dee0df;
  box-sizing: border-box;
  padding: 15px;
  margin-right: 20px;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
    background-color: #2e74e6;
    color: white;
  }
`;

const ItemHeader = styled.div`
  font-weight: 400;
  font-size: 20px;
`;

const ItemDescription = styled.div`
  font-size: 14px;
  padding-top: 10px;
`;

const ImageWarpper = styled.div`
  width: 750px;
  margin: 0 auto;
  margin-top: 20px;
`;

const JetScreen = styled.img`
  width:100%;
`;

function PrivateJet() {

  const navigate = useNavigate()
    function learnMoreDocking() {
      navigate('/yacht');
    }

    function learnMoreConcierge() {
      navigate('/concierge');
    }

  return (
    <Wrapper>
      <HeaderComponent />
      <CoverComponent />
        <ServicesWrapper>      
        <Description></Description> 
        <Services>
          <Items>
            <ItemHeader>Flight Crew</ItemHeader>
            <ItemDescription>Manage your flight crew - easy human management interface</ItemDescription>
          </Items>
          <Items>
            <ItemHeader>Maintanence</ItemHeader>
            <ItemDescription>Get detailed information about private jet maintanence / scheduled maintanence</ItemDescription>
          </Items>
          <Items>
            <ItemHeader>Charter Solutions</ItemHeader>
            <ItemDescription>Manage / Plan Charters using Jetnemo Charter Solution</ItemDescription>
          </Items>
          <Items>
            <ItemHeader>Turnkey Operations</ItemHeader>
            <ItemDescription>Manage turnkey operations for private jets and sync with third-party services</ItemDescription>
          </Items>
        </Services>
      </ServicesWrapper>
      <ImageWarpper>
        <JetScreen src = {PJScreen1} />
      </ImageWarpper>
      <FooterComponent />
    </Wrapper>
  );
}

export default PrivateJet;
