import styled from "styled-components";
import { HiChevronRight, HiArrowNarrowRight } from "react-icons/hi";
import YachtImages  from "../assets/image/yacht_image_licensed.e78c222b.jpg";
import PrivateJetImage  from "../assets/image/private_jet_licensed.059a6259.jpg";
import HeaderComponent, { Logo } from "../components/header.component";
import CoverComponent from "../components/cover.component";
import FooterComponent from "../components/footer.component";
import { useNavigate } from "react-router";
import PageWrapperComponent from "../components/PageWrapper.component";

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};

const Wrapper = styled.div`
  width: 100%;
  height: 80px;
`;
const ServicesWrapper = styled.div`
  width: 800px;
  min-height: 500px;
  margin-top: 90px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  @media ${devices.tablet} {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    width: 100%;
  }
`;


const ContactList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const HeaderItem = styled.div`
  font-weight: 600px;
  font-family: tahoma;
  font-size: 20px;
`;

const ContactInfo = styled.div`
  font-familiy: tahoma;
  margin-top: 5px;
  margin-left: 0px;
`;

const AddressBar= styled.div`
  font-familiy: tahoma;
`;

function Contact() {

  const navigate = useNavigate()
    function learnMoreDocking() {
      navigate('/yacht');
    }

    function learnMoreConcierge() {
      navigate('/concierge');
    }

  return (
    <Wrapper>
      <HeaderComponent />
      <CoverComponent /> <ServicesWrapper>
          <ContactList>
            <ContactWrapper>
              <HeaderItem>General</HeaderItem>
              <ContactInfo>Email: contact@jetnemo.com</ContactInfo>
            </ContactWrapper>
            <ContactWrapper>
              <HeaderItem>Global</HeaderItem>
              <ContactInfo>Email: info@jetnemo.com</ContactInfo>
            </ContactWrapper>
            <ContactWrapper>
              <HeaderItem>Sales</HeaderItem>
              <ContactInfo>Email: sales@jetnemo.com</ContactInfo>
            </ContactWrapper>
          <AddressBar>
          Address: Tbilisi, Georgia 0100
          </AddressBar>
          </ContactList>
        </ServicesWrapper>
      <FooterComponent />
    </Wrapper>
  );
}

export default Contact;
