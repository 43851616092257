import React from 'react';
import './styles/App.css';
import AppRoutes from './routes/public';


function App() {
  return (
    <>
    <AppRoutes />
    </>
  );
}

export default App;