import { Link } from "react-router-dom";
import styled from "styled-components";
import { Logo, LogoWhite } from "./header.component";

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};


const FooterMenu = styled.div`
  display: flex;
`;

const Footer = styled.div`
  width: 100%;
  height: 300px;
  background-color: #292828;
  margin-top: 0px;
  @media ${devices.tablet} {
    display: none;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 900px;
  padding-top: 50px;
`;

const CompanyHeader = styled.div`
  font-weight: 600;
  font-family: sans-serif;
  color: white;
`;

const CompanyList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  font-family: sans-serif;
  color: white;
  font-size: 15px;
  font-weight: 200;
`;

const FooterAbout = styled.div``;

const FooterCopy = styled.div`
  text-align: right;
  color: white;
  font-family: sans-serif;
`;

const Copyright = styled.div`
  color: #ebe6e6;
  font-family: sans-serif;
  font-size: 15px;
  margin-top: 10px;
  font-weight: 100;
`;

const FooterCompany = styled.div``;

const FooterProducts = styled.div`
  margin-left: 80px;
`;

const LinkTo = styled(Link)`
  text-decoration: none;
  color: white;
`;

function FooterComponent() {
    return (
        
      <Footer>
      <FooterWrapper>
      <FooterAbout>
        <FooterMenu>
          <FooterCompany>
            <CompanyHeader>COMPANY</CompanyHeader>
            <CompanyList><LinkTo to = '/about'>About Us</LinkTo></CompanyList>
            <CompanyList><LinkTo to = '/careers'>Careers</LinkTo></CompanyList>
            <CompanyList><LinkTo to = '/contact-us'>Contact Us</LinkTo></CompanyList>
            <CompanyList><LinkTo to = '/press'>Press</LinkTo></CompanyList>
          </FooterCompany>
          <FooterProducts>
          <CompanyHeader>PRODUCTS</CompanyHeader>
            <CompanyList><LinkTo to = '/private-jet'>Private Jet Management</LinkTo></CompanyList>
            <CompanyList><LinkTo to = '/yacht'>Yacht Management</LinkTo></CompanyList>
            <CompanyList><LinkTo to = '/helicopter'>Helicopter Management</LinkTo></CompanyList>
            <CompanyList><LinkTo to = '/concierge'>Concierge</LinkTo></CompanyList>
            <CompanyList><LinkTo to = '/membership'>Membership</LinkTo></CompanyList>
          </FooterProducts>
        </FooterMenu>
      </FooterAbout>
      <FooterCopy>
        <LogoWhite>Jetnemo</LogoWhite>
        <Copyright>Jetnemo Inc. © 2022. All rights reserved.</Copyright>
      </FooterCopy>
      </FooterWrapper>
    </Footer>
  );
};

export default FooterComponent;
      
      