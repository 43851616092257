import styled from "styled-components";
import CoverImage  from "../assets/image/water-3166432_1920.jpg";

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};



const MainWrapper = styled.div`
  width: 100%;
  height: 1px;
  background-color: #c9c8c5;
`;

const Page = styled.div`
    font-family: tahoma;
    padding-left: 20px;
    padding-top: 20px;
    font-size: 20px;
`;

const PageDescription = styled.div`
    font-family: tahoma;
    padding-left: 20px;
    padding-top: 5px;
    font-size: 15px;
    padding-bottom: 20px;
`;

function PageWrapperComponent(props: any) {
    return (
        <MainWrapper>
            <Page>{props.pageName}</Page>
            <PageDescription>{props.pageDescription}</PageDescription>
        </MainWrapper>
  );
};

export default PageWrapperComponent;
      
      