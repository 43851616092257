import { FC } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "../Home";
import About from "../pages/About";
import Careers from "../pages/Careers";
import Concierge from "../pages/concierge";
import Contact from "../pages/Contact";
import Demo from "../pages/Demo";
import Helicopter from "../pages/Helicopter";
import LoginMembership from "../pages/membership/index";
import Membership from "../pages/Membership";
import Press from "../pages/Press";
import PrivateJet from "../pages/Privatejet";
import Yacht from "../pages/Yacht";
import Dashboard from "../pages/dashboard/dashboard";
import ProtectedRoute from "./protected";
import { AuthProvider } from "../hooks/useAuth";
import AddVesselPage from "../pages/dashboard/addVessel";
import CreateCrewPage from "../pages/dashboard/createCrew";
import CrewCreatePage from "../pages/dashboard/createCrewPage";
import CrewDetailsPage from "../pages/dashboard/crewDetailsPage";
import EmployeeDashboard from "../pages/employee/dashboardPage";
import SettingsPage from "../pages/dashboard/settingsPage";

const routes = [
  {
    path: `/`,
    component: HomePage,
    exact: true,
  },
  {
    path: `/private-jet`,
    component: PrivateJet,
    exact: true,
  },
  {
    path: `/yacht`,
    component: Yacht,
    exact: true,
  },
  {
    path: `/helicopter`,
    component: Helicopter,
    exact: true,
  },
  {
    path: `/concierge`,
    component: Concierge,
    exact: true,
  },
  {
    path: `/membership`,
    component: Membership,
    exact: true,
  },
  {
    path: `/demo`,
    component: Demo,
    exact: true,
  },
  {
    path: `/about`,
    component: About,
    exact: true,
  },
  {
    path: `/careers`,
    component: Careers,
    exact: true,
  },
  {
    path: `/contact-us`,
    component: Contact,
    exact: true,
  },
  {
    path: `/press`,
    component: Press,
    exact: true,
  },
  {
    path: `/membership/login`,
    component: LoginMembership,
    exact: true,
  },
  {
    path: `/crew-detail/:crewId`,
    component: CrewDetailsPage,
    exact: true,
  },
];

const protectedRoutes = [
  {
    path: '/dashboard',
    exact: true,
    component: Dashboard
  },
  {
    path: '/add-vessel',
    exact: true,
    component: AddVesselPage
  },
  {
    path: '/my-crew',
    exact: true,
    component: CreateCrewPage
  },
  {
    path: '/create-crew',
    exact: true,
    component: CrewCreatePage
  },
  {
    path: '/employee',
    exact: true,
    component: EmployeeDashboard
  },
  {
    path: '/settings',
    exact: true,
    component: SettingsPage
  },
];



const AppRoutes: FC = () => {
  return (
    <Router>
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={<route.component />} />
        ))}
        {protectedRoutes.map((route) => (
           <Route
            key={route.path}
            path={route.path}
            element={
             <ProtectedRoute>
               <AuthProvider>
               <route.component />
               </AuthProvider>
             </ProtectedRoute>
           }
         />
        ))}
        </Routes>
    </Router>
  );
};
export default AppRoutes;
