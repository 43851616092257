import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const ProtectedRoute = ({ children }) => {
    const userData = localStorage.getItem('user_data');
    const token = JSON.parse(userData!);
  if (!token ) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectedRoute;

/* 
import { Route, Navigate } from 'react-router-dom';

const ProtectedRoute = ({component: Component, ...rest}) => {
    const userData = localStorage.getItem('user_data');
    const token = JSON.parse(userData!);
    return (
        <Route  
            {...rest}
            element = {(props)=> {
                    return <Component {...rest} {...props} />;
               
            }}
        />
    );
};

export default ProtectedRoute;
*/