import styled from "styled-components";
import CoverImage  from "../assets/image/water-3166432_1920.jpg";

const sizes = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

export const devices = {
  mobileS: `(max-width: ${sizes.mobileS})`,
  mobileM: `(max-width: ${sizes.mobileM})`,
  mobileL: `(max-width: ${sizes.mobileL})`,
  tablet: `(max-width: ${sizes.tablet})`,
  laptop: `(max-width: ${sizes.laptop})`,
  laptopL: `(max-width: ${sizes.laptopL})`,
  desktop: `(max-width: ${sizes.desktop})`,
};


const Cover = styled.div`
  width: 100%;
  height: 370px;
  background-color: #f3efff;
  background-position: bottom;
  background-size: cover;
`;

const CoverWrapper = styled.div`
  width: 800px;
  margin:0 auto;
  display: flex;
  flex-direction: column;
  color: #fafafa;
  @media ${devices.tablet} {
    padding: 30px;
    box-sizing: border-box;
    padding-top: 10px;
    width: 100%;
  }
`;

const Line = styled.div`
  width: 10px;
  height: 45px;
  background: #7cbd20;
  margin-top: 0px;
  margin-right: 10px;
`;

const CoverHeading =  styled.div`
  margin-top: 60px;
  font-size: 45px;
  line-height: 112%;
  font-weight: 700;
  color: #7958e9;
  font-family: 'True Sans', sans-serif;
  margin: 0 auto;
  flex-wrap: wrap;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  padding-top: 50px;
  display: flex;
  @media ${devices.tablet} {
    font-size: 22px;
  }
`;

const CoverDescription = styled.div`
  font-size: 20px;
  margin-top: 30px;
  font-weight: 100;
  color: black;
  text-align: center;
  font-family: sans-serif;
`;


function CoverComponent() {
    return (
        <Cover>
          <CoverWrapper>
            <CoverHeading>
              Crew Management Solutions For Private Jets, Yachts & Helicopters
            </CoverHeading>
            <CoverDescription>
              SaaS platform enables you as a vessel owner configure / hire crew<br/> for your private jet, yacht, helicopter. <br />
            </CoverDescription>
          </CoverWrapper>
        </Cover>
  );
};

export default CoverComponent;
      
      